import { useEffect } from 'react'

function Freschat() {
  useEffect(() => {
    
    // FRESHCHAT
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.scr = 'https://wchat.freshchat.com/js/widget.js';
    script.setAttribute('src', 'https://wchat.freshchat.com/js/widget.js')
    document.head.appendChild(script);

    setTimeout(() => {
      const scriptFS = document.createElement('script');
      let scriptText = document.createTextNode(
        `window.fcWidget.init({
              token: "64e8b486-3909-439c-bdb0-767acc48eec0",
        host: "https://foursource.freshchat.com"
          });
        `);
        scriptFS.appendChild(scriptText);
        document.body.appendChild(scriptFS);
    }, 2000);
    // FRESHCHAT END

  }, [])

  return null
}

export default Freschat
