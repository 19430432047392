import background from '../assets/images/covers/contact-us-background.svg'

// Locations
import Turkey from '../assets/images/locations/izmir-turkey.jpg'
import Porto from '../assets/images/locations/porto-portugal.jpg'
import Berlin from '../assets/images/locations/berlin-germany.jpg'

export const contactUsContent = (t) => {
    return {
      cover: {
        background: background,
        title: t('CONTACT_US_TITLE'),
        text: t(
          'CONTACT_US_TEXT'
        ),
        hideImageMobile: true
      },
      berlin: {
        type: t('HEADQUARTERS'),
        place: t('BERLIN'),
        address: t('BERLIN_ADDRESS'),
        email: 'hello@foursource.com',
        emailPreview: 'hello [@] foursource.com',
        image: Berlin
      },
      porto: {
        type: t('OFFICE'),
        place: t('PORTO'),
        address: t('PORTO_ADDRESS'),
        email: 'portugal@foursource.com',
        emailPreview: 'portugal [@] foursource.com',
        image: Porto
      },
      turkey: {
        type: t('OFFICE'),
        place: t('IZMIR'),
        address: t('IZMIR_ADDRESS'),
        email: 'turkey@foursource.com',
        emailPreview: 'turkey [@] foursource.com',
        image: Turkey
      }
    }
};