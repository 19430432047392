import * as React from 'react'

import { Typography } from '@mui/material'

// Styles
import './locationCard.scss'

const LocationCard = ({ content }) => {
  return (
    <div className="bg-white p-0 card-location">
      <div className="location-info pt-s-32 pb-s-32 ps-s-32 pe-s-32">
        <Typography variant='subhead'>{content.type}</Typography>
        <Typography variant='h3' sx={{ color: 'bluishGrey.ten', mb: 3 }}>
          {content.place}
        </Typography>
        <Typography variant='body2'>{content.address}</Typography>
        <Typography variant='body2Bold' className="pt-s-8"><a href={'mailto:' + content.email}>{content.emailPreview}</a></Typography>
      </div>
      <img className="w-100" src={content.image} alt={content.place}/>
    </div>
  )
}

export default LocationCard
