import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

// Components
import Carousel from '../components/Carousel'
import Cover from '../components/Cover'
import Cta from '../components/Cta'
import LayoutFreshchat from '../components/layoutFreshchat'
import Seo from '../components/seo'
import Testimonials from '../components/Testimonials'
import LocationCard from '../components/LocationCard'

// Content
import { logoSlideContent } from '../content/logoSlide'
import { ctaContent } from '../content/cta'
import { contactUsContent } from '../content/contactUs'
import { Container, Typography } from '@mui/material'

// Styling
import '../styles/contactUs.scss'

const ContactUsPage = () => {
  const { t } = useTranslation()

  return (
    <LayoutFreshchat headerWhite={true}>
      <Cover content={contactUsContent(t).cover} />
      <div className='bg-light text-center pt-s-64 pb-s-64 background-touch'>
        <Typography variant='subhead'>{t('GET_IN_TOUCH')}</Typography>
        <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 3 }}>
          {t('WE_ARE_ALWAYS_HERE_FOR_YOU')}
        </Typography>
        <Container maxWidth='xl'>
          <div className='d-flex gap-3 location-row'>
            <LocationCard content={contactUsContent(t).berlin}></LocationCard>
            <LocationCard content={contactUsContent(t).porto}></LocationCard>
          </div>
          <div className='d-flex location-row margin justify-content-center'>
            <LocationCard content={contactUsContent(t).turkey}></LocationCard>
          </div>
        </Container>
      </div>
      <Carousel content={logoSlideContent('big', 'default', true).carousel} />
      <Testimonials />
      <Cta content={ctaContent} />
    </LayoutFreshchat>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='Contact Us'
    description='Need something? We are happy to help! Feel free to contact us by email and our team of experts will try to get back to you as soon as possible.'
    pathname={location.pathname}
  />
)

export default ContactUsPage

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
