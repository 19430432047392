import background1 from '../assets/images/cta/background1.jpg'

export const ctaContent = {
  title: 'Join now and start sourcing',
  subhead: 'Try For Free',
  bg: {
    image: background1,
  },
  buttons: [
    {
      text: 'Sign up',
      href: 'SIGNUP',
      color: 'primary',
    },
    {
      text: 'Join as a supplier',
      href: '/for-suppliers/',
      color: 'white',
      variant: 'outlined',
    },
  ],
}
