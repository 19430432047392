import * as React from 'react'
import useWindowSize from '../../utils/windowSize'

import { Typography } from '@mui/material'

// Styles
import './cover.scss' 

const Cover = ({ content }) => {
  const responsive = useWindowSize().responsive;
  var bg = responsive ? { background: '' } : { background: `url('${content.background}') no-repeat right ${content.hideImageMobile ? 'center': 'bottom'}` }
  return (
    <div className={'cover mx-auto mt-s-80 ' + (content.hideImageMobile ? '' : 'mb-s-64')}>
      <div
        style={bg}
        className={'coverBgImg d-flex flex-row flex-wrap justify-content-center justify-content-lg-between'}
      >
        <div className='d-flex flex-column mt-s-80 coverLeft'>
          <Typography variant={content.hideImageMobile ? 'h2' : 'h1'} sx={{ color: 'bluishGrey.ten', mb: 5 }}>
            {content.title}
          </Typography>
          <Typography variant={content.hideImageMobile ? 'body1Bold' : 'body1'} sx={{ mb: 2 }}>{content.text}</Typography>
          {content.cta && (content.cta?.newTab ? <a href={content.cta.url} target="_blank" rel="noreferrer" className='btn btn-sm btn-primary mt-s-48 mb-md-s-68'>
            {content.cta.text}
          </a> : <a href={content.cta?.url} className='btn btn-sm btn-primary mt-s-48 mb-md-s-68'>
            {content.cta?.text}
          </a>)}
          {content.hideImageMobile && 
          <img
            className="mobile-image d-lg-none d-md-inline" src={content.background} alt={content.title} 
          />}
        </div>
      </div>
    </div>
  )
}

export default Cover
