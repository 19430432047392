import * as React from 'react'
import Header from './Header'
import Footer from './Footer'
import CookiesMessage from './Cookies'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import { theme } from '../theme'
import Scripts from './scripts'
import Freshchat from './freshchat'

import GlobalStyle from '../assets/styles/globalStyles'

const LayoutFreshchat = (props) => {
  return (
    <>
      <Scripts />
      <Freshchat />
      <GlobalStyle />
      <CookiesMessage />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header headerWhite={props.headerWhite} />
        <main>{props.children}</main>
        <Footer mode={'light'} />
      </ThemeProvider>
    </>
  )
}

export default LayoutFreshchat
